<script>
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		otherProperties() {
			return this.agreementData.otherProperties;	
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},
	methods: {
		addOtherProperty() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals: []
			};
		},
	},
	mounted(){
		for (let i = 0; i < this.agreementData.otherProperties.length; i++) {
			const otherProperty = this.agreementData.otherProperties[i];
			if (!otherProperty.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(otherProperty, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header">Other Property</h4>

		<list-item
			v-slot="{ item: otherProperty, index: index }"
			v-model="otherProperties"
			:object-prototype="addOtherProperty"
			:testid="'other-property-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (otherProperties.length-1))">

			<input-group
				v-model="otherProperty.description"
				class="mb-2"
				input-cols="4"
				label="Description of Property"
				reset-undefined
				:testid="'other-property-description-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="otherProperty.value"
				reset-undefined
				class="my-3"
				dollar
				label="Value of Property as of date of separation/date of agreement"
				:testid="'other-property-value-'+ index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="otherProperty.owner"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="Who owns the Property?"
				reset-undefined
				partner
				select
				:options="['Joint']"
				:testid="'other-property-owner-'+ index"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
			<yes-no-choice
				v-model="otherProperty.hasExclusions"
				reset-undefined
				label="Are there any exclusions related to the Property?"
				class="my-3"
				:testid="'other-property-has-exclusions-'+ index"
				:disabled="isViewMode"
			/>

			<conditional-group class="my-3" :condition="otherProperty.hasExclusions">
				<exclusion-table
					v-model="otherProperty.exclusions"
					:agreement-data="agreementData"
					:testid="'other-property-exclusion-'+ index + '-'"
					:disabled="isViewMode"
				/>
			</conditional-group>
			<radio-group
				v-model="otherProperty.plan"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="What will the owner do with the Property upon separation?"
				reset-undefined
				select
				:options="[
					{ value: 'keep', text: 'Keep' },
					{ value: 'transfer', text: 'Transfer' },
					{
						value: 'sell',
						text: 'Sell and split the proceeds equally',
					},
				]"
				:testid="'other-property-upon-separation'+ index"
				:disabled="isViewMode"
			/>
		</template>
			<conditional-group
				class="my-3"
				:condition="otherProperty.plan === 'transfer'"
			>
				<radio-group
					v-model="otherProperty.recipient"
					class="my-3"
					:agreement-data="agreementData"
					input-cols="4"
					label="Who will the other property registration be transferred to?"
					reset-undefined
					partner
					select
					:testid="'other-property-registration-transferred-to-'+ index"
					:disabled="isViewMode"
				/>
			</conditional-group>
			<template v-if="!clientUserAgreement">
			<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="otherProperty.additionalClausesToRecitals" :testid="'other-property-add-clause-to-recitals-'+ index + '-'"
				:disabled="isViewMode"/>
			<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="otherProperty.additionalClauses" :testid="'other-property-add-clause-'+ index + '-'"
				:disabled="isViewMode"/>
		</template>
	</template>
		</list-item>
	</b-card>
</template>
