<script>
import partnerChoices from '@/mixins/partnerChoices';
import captialGains from '@/lib/capitalGains';
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerChoices],
	props: {
		agreementData: Object,
		index: Number,
		propertyIndex: Number,
		updateAdditionalRealProperties: Function,
		value: Object,
		testid:String,
	},
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		additionalRealProperty() {
			return this.value;
		},
		captialGainsCalculation() {
			const {
				currentValue,
				purchasePrice,
				taxYearsOwned,
				taxYearsPrincipalResidence,
			} = this.additionalRealProperty;

			const captialGainsValue = captialGains(
				currentValue,
				purchasePrice,
				taxYearsPrincipalResidence,
				taxYearsOwned
			);
			return captialGainsValue;
		},
		partnerResponsibleChoices() {
			return this.partnerChoices.map((partner) => ({
				...partner,
				text: `${partner.text} will be solely responsible`,
			}));
		},
		partnerRentChoices() {
			return this.partnerChoices.map((partner) => ({
				...partner,
				text: `${partner.text} will keep rent`,
			}));
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},

	watch: {
		additionalRealProperty: {
			handler: function (val) {
				this.updateAdditionalRealProperties(val, this.index);
			},
			deep: true,
		},
	},

	methods: {
		newLiability() {
			return {
				lastFourDigits: ''
			};
		},
		parsetoString(index){
			return index.toString()
		}
	},
};
</script>

<template>
	<div>
		<address-input-group
			v-model="additionalRealProperty.address"
			class="my-3"
			:testid="'additional-property-address-'+testid"
			:disabled="isViewMode"
		/>

		<radio-group
			v-model="additionalRealProperty.ownership"
			class="my-3"
			:agreement-data="agreementData"
			input-cols="4"
			label="How is the property owned?"
			reset-undefined
			partner
			select
			:options="['Jointly']"
			:testid="'additional-property-ownership-'+testid"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<date-input
			v-model="additionalRealProperty.purchaseDate"
			reset-undefined
			class="my-3"
			input-cols="6"
			label="On what date was the property purchased?"
			:testid="'additional-property-purchase-date-'+testid"
			:disabled="isViewMode"
		/>

		<input-group
			v-model="additionalRealProperty.purchasePrice"
			reset-undefined
			class="my-3"
			dollar
			label="What was the purchase price of the property?"
			:testid="'additional-property-purchase-price-'+testid"
			:disabled="isViewMode"
		/>

		<input-group
			v-model="additionalRealProperty.currentValue"
			reset-undefined
			class="my-3"
			dollar
			label="What is the current value of the property?"
			:testid="'additional-property-current-value-'+testid"
			:disabled="isViewMode"
		/>
		<template v-if="!clientUserAgreement">

		<yes-no-choice
			v-model="additionalRealProperty.hadAppraisal"
			label="Was the value determined by an appraisal"
			reset-undefined
			class="my-3"
			cols="9"
			:options="[
				{ value: true, text: 'Yes' },
				{
					value: false,
					text: 'No, the parties agreed to the value',
				},
			]"
			:testid="'additional-property-had-appraisal-'+testid"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.hadAppraisal"
		>
			<date-input
				v-model="additionalRealProperty.appraisalDate"
				label="Date of appraisal:"
				class="my-3"
				:testid="'additional-property-appraisal-date-'+testid"
				:disabled="isViewMode"
			/>
		</conditional-group>
		</template>

		<p>
			Are there any liabilities registered on title to the property? If
			so, add details of each individual liability below:
		</p>

		<list-item
			v-slot="{ item: liability, index: index }"
			v-model="additionalRealProperty.liabilities"
			:object-prototype="newLiability"
			:testid="'additional-property-new-item-'"
			:disabled="isViewMode"
		>
			<input-group
				v-model="liability.lender"
				input-cols="8"
				label="Name of Financial Institution or Lender"
				class="mb-3"
				:testid="'additional-property-'+testid+'-institution-name-'+parsetoString(index)"
				:disabled="isViewMode"
			/>

			<b-form-group
				:id="'input-registered-'+index +'-'+ propertyIndex"
				label="Liabilities registered on the property"
				:label-for="'registered-'+index +'-'+ propertyIndex"
			> 
				<b-form-select
					:id="'registered-'+index +'-'+ propertyIndex"
					v-model="liability.registered"
					size="sm"
					:options="[
						{ text: 'Mortgage', value: 'Mortgage' },
						{ text: 'Line of Credit', value: 'Line of Credit' }, // value fix
						{ text: 'Other', value: 'other' },
					]"
					:data-testid="'additional-property-'+testid+'-registered-liability-'+parsetoString(index)"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="liability.registered === 'other'"
			>
				<input-group
					v-model="liability.type"
					class="my-3"
					reset-undefined
					input-cols="8"
					label="Type of liability"
					:testid="'additional-property-'+testid+'-liability-type-'+index"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<input-group
				v-model="liability.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'additional-property-'+testid+'-last-four-digits-'+parsetoString(index)"
				:disabled="isViewMode"
			/>

			<b-form-group
				:id="'input-responsible-'+index +'-'+ propertyIndex"
				label="Which party is responsible for the liability?"
				:label-for="'responsible-'+index +'-'+ propertyIndex"
			>
				<b-form-select
					:id="'responsible-'+index +'-'+ propertyIndex"
					v-model="liability.responsible"
					size="sm"
					:options="[
						{
							text: 'Parties are jointly responsible',
							value: 'mortgage',
						},
						...partnerChoices,
					]"
					:data-testid="'additional-property-'+testid+'-liability-responsibility-'+parsetoString(index)"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<input-group
				v-model="liability.balance"
				reset-undefined
				class="my-3"
				dollar
				label="What is the amount owing?"
				:testid="'additional-property-'+testid+'-amount-owning-'+parsetoString(index)"
				:disabled="isViewMode"
			/>
		</list-item>

		<!-- COMMENTING OUT CAPITAL GAINS FOR BETA -->
		<!-- <yes-no-choice
			label="Was the property ever used as the parties’ principal residence?"
			v-model="additionalRealProperty.hasBeenPrincipalResidence"
			resetUndefined
			class="my-3"
			cols="3"
		/>

		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.hasBeenPrincipalResidence"
		>
			<input-group
				class="my-3"
				resetUndefined
				input-cols="4"
				v-model="additionalRealProperty.taxYearsOwned"
				label="How many tax years was the property owned?"
			/>
			<input-group
				label="How many tax years was the property declared as principal residence?"
				v-model="additionalRealProperty.taxYearsPrincipalResidence"
				class="my-3"
				resetUndefined
				input-cols="4"
			/>
		</conditional-group> -->

		<!-- <yes-no-choice
			label="Would you like to factor in future capital gains tax payable to determine the shared equity of the property?"
			v-model="additionalRealProperty.factorInCapitalGains"
			resetUndefined
			class="my-3"
			cols="3"
		/>

		<conditional-group 
			class="my-3"
			:condition="additionalRealProperty.factorInCapitalGains"
		>
			An estimated capital gain tax of ${{ this.captialGainsCalculation }}
			was calculated based on a tax rate of 35 percent.

			<yes-no-choice
				label="Would you like to use this amount?"
				v-model="additionalRealProperty.useCapitalGainsValue"
				resetUndefined
				class="my-3"
				cols="3"
			/> -->

		<!--<conditional-group
				class="my-3"
				:condition="!additionalRealProperty.useCapitalGainsValue"
			>
				 <input-group
					class="my-3"
					resetUndefined
					input-cols="6"
					dollar
					v-model="additionalRealProperty.capitalGainsOverride"
					label="Override amount: "
				
			</conditional-group> /> 
		</conditional-group> -->
		<template v-if="!clientUserAgreement">
		<yes-no-choice
			v-model="additionalRealProperty.hasExclusions"
			label="Are there any exclusions related to the property?"
			reset-undefined
			class="my-3"
			:testid="'additional-property-has-exclusion-'+testid"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.hasExclusions"
		>
			<exclusion-table
				v-model="additionalRealProperty.exclusions"
				:agreement-data="agreementData"
				:testid="'additional-property-exclusion-'+testid+'-'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<b-form-group
			:id="'input-propertyStatus-'+ propertyIndex"
			label="What will the parties do with the property?"
			:label-for="'propertyStatus-'+ propertyIndex"
			class="my-1"
		>
			<b-form-select
				:id="'propertyStatus-'+ propertyIndex"
				v-model="additionalRealProperty.propertyStatus"
				size="sm"
				:options="[
					{
						text: 'One party will keep the property',
						value: 'keep',
					},
					{
						text: 'Parties will sell the property and split the proceeds',
						value: 'sell',
					},
					{
						text: 'The parties will continue to co-own the property for a period of time',
						value: 'co-own',
					},
				]"
				:data-testid="'additional-property-property-status-'+testid"
				class="col-lg-8"
				:disabled="isViewMode"
			/>
		</b-form-group>

		<!-- Keep -->
		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.propertyStatus === 'keep'"
		>
			<b-form-group
				:id="'input-additionalRealProperty-keep-'+ propertyIndex"
				label="Which party will keep the property?"
				label-for="keep"
			>
				<b-form-select
					:id="'additionalRealProperty-keep-'+ propertyIndex"
					v-model="additionalRealProperty.keep"
					size="sm"
					reset-undefined
					:options="[...partnerChoices]"
					:data-testid="'additional-property-keep-'+testid"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>
		</conditional-group>

		<!-- Sell -->
		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.propertyStatus === 'sell'"
		>
			<b-form-group
				:id="'input-propertyState-'+ propertyIndex"
				label="What is the current state of the property?"
				:label-for="'propertyState-'+ propertyIndex"
			>
				<b-form-select
					:id="'propertyState-'+ propertyIndex"
					v-model="additionalRealProperty.propertyState"
					size="sm"
					reset-undefined
					:options="[
						{
							text: 'To be listed for sale',
							value: 'to-be-listed',
						},
						{
							text: 'Listed for sale',
							value: 'listed',
						},
						{
							text: 'Contract for purchase and sale has been executed',
							value: 'contract-executed',
						},
					]"
					:data-testid="'additional-property-state-'+testid"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="
					additionalRealProperty.propertyState === 'to-be-listed'
				"
			>
				<yes-no-choice
					v-model="additionalRealProperty.upgradesBeforeSale"
					label="Will the parties make any upgrades to the property before they list it for sale?"
					reset-undefined
					class="my-3"
					cols="3"
					:testid="'additional-property-upgrades-before-sale-'+testid"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="additionalRealProperty.upgradesBeforeSale"
				>
					<b-form-group
						:id="'input-upgradesBeforeSale-'+ propertyIndex"
						label="How will the parties divide the cost of the upgrades?"
						:label-for="'upgradesBeforeSale-'+ propertyIndex"
					>
						<b-form-select
							:id="'upgradesBeforeSale-'+ propertyIndex"
							v-model="additionalRealProperty.upgradesBeforeSale"
							size="sm"
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'The will share costs as agreed',
									value: 'as-agreed',
								},
							]"
							:data-testid="'additional-property-cost-of-upgrades-'+testid"
							class="col-lg-8"
							:disabled="isViewMode"
						/>
					</b-form-group>
				</conditional-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.propertyState === 'listed'"
			>
				<input-group
					v-model="additionalRealProperty.realEstateAgent"
					input-cols="8"
					label="Name of real estate agent"
					class="mb-3"
					:testid="'additional-property-real-estate-agent-'+testid"
					:disabled="isViewMode"
				/>

				<input-group
					v-model="additionalRealProperty.listingPrice"
					label="Listing Price"
					class="mb-3"
					dollar
					:testid="'additional-property-listing-price-'+testid"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="
					additionalRealProperty.propertyState === 'contract-executed'
				"
			>
				<input-group
					v-model="additionalRealProperty.salePrice"
					label="What was the sale price of the property?"
					class="mb-3"
					dollar
					:testid="'additional-property-sale-price-'+testid"
					:disabled="isViewMode"
				/>

				<date-input
					v-model="additionalRealProperty.saleClosingDate"
					label="What is the closing date of the sale?"
					class="mb-3"
					:testid="'additional-property-sale-closing-date-'+testid"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<yes-no-choice
				v-model="additionalRealProperty.someoneWillLiveUntilSold"
				label="Will either party live at the property until it is sold?"
				reset-undefined
				class="my-3"
				:testid="'additional-property-will-live-until-sold-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.someoneWillLiveUntilSold"
			>
				<b-form-group
					:id="'input-additionalRealProperty-liveInUntilSold-'+ propertyIndex"
					label="Which party?"
					label-for="liveInUntilSold"
				>
					<b-form-select
						:id="'additionalRealProperty-liveInUntilSold-'+ propertyIndex"
						v-model="additionalRealProperty.liveInUntilSold"
						size="sm"
						reset-undefined
						:options="[...partnerChoices]"
						:data-testid="'additional-property-live-in-until-sold-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="!additionalRealProperty.someoneWillLiveUntilSold"
			>
				<b-form-group
					:id="'input-useProperty-'+ propertyIndex"
					label="Will either party have use of the property?"
					:label-for="'useProperty-'+ propertyIndex"
				>
					<b-form-select
						:id="'useProperty-'+ propertyIndex"
						v-model="additionalRealProperty.useProperty"
						size="sm"
						reset-undefined
						:options="[
							...partnerChoices,
							{ text: 'Both', value: 'both' },
							{ text: 'Neither', value: 'neither' },
						]"
						:data-testid="'additional-property-usage-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>

			<b-form-group
				:id="'input-manageProperty-'+ propertyIndex"
				label="How will the parties manage property expenses until it is sold?"
				:label-for="'manageProperty-'+ propertyIndex"
			>
				<b-form-select
					:id="'manageProperty-'+ propertyIndex"
					v-model="additionalRealProperty.manageProperty"
					size="sm"
					reset-undefined
					:options="[
						...partnerResponsibleChoices,
						{
							text: 'They will share costs equally',
							value: 'equally',
						},
						{
							text: 'They will share costs as agreed',
							value: 'as-agreed',
						},
						{
							text: 'They will share costs based on an agreed-upon schedule',
							value: 'schedule',
						},
					]"
					:data-testid="'additional-property-management-expenses-'+testid"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<yes-no-choice
				v-model="additionalRealProperty.currentlyRented"
				label="Is their currently rental income being generated by the property?"
				reset-undefined
				class="my-3"
				:testid="'additional-property-currently-rented-income-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.currentlyRented"
			>
				<input-group
					v-model="additionalRealProperty.rentalAmount"
					reset-undefined
					class="my-3"
					dollar
					label="What is the rental income amount?"
					:testid="'additional-property-rental-amount-'+testid"
					:disabled="isViewMode"
				/>

				<b-form-group
					:id="'input-manageRentalIncome-'+ propertyIndex"
					label="How will parties manage rental income from the property until it is sold?"
					:label-for="'manageRentalIncome-'+ propertyIndex"
				>
					<b-form-select
						:id="'manageRentalIncome-'+ propertyIndex"
						v-model="additionalRealProperty.manageRentalIncome"
						size="sm"
						reset-undefined
						:options="[
							...partnerRentChoices,
							{
								text: 'They will share the rent equally',
								value: 'equally',
							},
							{
								text: 'They will share the Rent as agreed between them',
								value: 'as-agreed',
							},
						]"
						:data-testid="'additional-property-rental-income-management-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>
		</conditional-group>

		<!-- co-own -->
		<conditional-group
			class="my-3"
			:condition="additionalRealProperty.propertyStatus === 'co-own'"
		>
			<b-form-group
				:id="'input-coOwnDecision-'+ propertyIndex"
				label="The parties will co-own until they purchase or sell, as decided on:"
				:label-for="'coOwnDecision-'+ propertyIndex"
			>
				<b-form-select
					:id="'coOwnDecision-'+ propertyIndex"
					v-model="additionalRealProperty.coOwnDecision"
					size="sm"
					:options="[
						{ text: 'A specific date', value: 'specific-date' },
						{
							text: 'On a date as agreed upon between the parties',
							value: 'arranged-date',
						},
					]"
					:data-testid="'additional-property-co-own-decision-'+testid"
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="
					additionalRealProperty.coOwnDecision === 'specific-date'
				"
			>
				<date-input
					v-model="additionalRealProperty.coOwnDecisionDate"
					label="Specified Date"
					class="mb-3"
					:testid="'additional-property-co-own-decision-date-'+testid"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<yes-no-choice
				v-model="additionalRealProperty.purchaseAdditionalProperty"
				label="Would either party like the first option to purchase the other party's interest in the property?"
				reset-undefined
				class="my-3"
				:testid="'additional-property-purchase-other-party-property-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.purchaseAdditionalProperty"
			>
				<b-form-group
					:id="'input-whoCanPurchase-'+ propertyIndex"
					label="Which party will be given the first opportunity to exercise the option to purchase?"
					:label-for="'whoCanPurchase-'+ propertyIndex"
				>
					<b-form-select
						:id="'whoCanPurchase-'+ propertyIndex"
						v-model="additionalRealProperty.whoCanPurchase"
						size="sm"
						:options="[...partnerChoices]"
						:data-testid="'additional-property-who-can-purchase-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>

			<yes-no-choice
				v-model="additionalRealProperty.someoneWillLiveUntilSold"
				label="Will either party live at the property until it is sold?"
				reset-undefined
				class="my-3"
				:testid="'additional-property-either-party-live-until-sold-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.someoneWillLiveUntilSold"
			>
				<radio-group
					:id="'input-additionalRealProperty-liveInUntilSold-'+ propertyIndex"
					v-model="additionalRealProperty.liveUntilSold"
					class="mb-3"
					input-cols="6"
					label="Which party?"
					reset-undefined
					select
					:options="[...partnerChoices]"
					:testid="'additional-property-who-live-'+testid"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="additionalRealProperty.liveUntilSold"
				>
					<yes-no-choice
						:id="'input-additionalRealProperty-personLivingInResidenceResponsibleForExpenses-'+ propertyIndex"
						v-model="
							additionalRealProperty.personLivingInResidenceResponsibleForExpenses
						"
						label="Will this party party be solely responsible for the expenses?"
						reset-undefined
						class="my-3"
						:testid="'additional-property-solely-responsible-for-expenses-'+testid"
						:disabled="isViewMode"
					/>

					<conditional-group
						class="my-3"
						:condition="
							!additionalRealProperty.personLivingInResidenceResponsibleForExpenses
						"
					>
						<radio-group
							:id="'input-additionalRealProperty-manageExpenses-'+ propertyIndex"
							v-model="additionalRealProperty.manageExpenses"
							select
							label="How will the parties manage the property expenses until it is sold?"
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'They will share costs as agreed',
									value: 'as-agreed',
								},
								{
									text: 'They will share costs based on an agreed-upon schedule',
									value: 'schedule',
								},
							]"
							:testid="'additional-property-property-management-until-sold-'+testid"
							:length="'long'"
							:disabled="isViewMode"
						/>
					</conditional-group>
				</conditional-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="!additionalRealProperty.someoneWillLiveUntilSold"
			>
				<b-form-group
					:id="'input-useProperty-'+ propertyIndex"
					label="Will either party have use of the property?"
					:label-for="'useProperty-'+ propertyIndex"
				>
					<b-form-select
						:id="'useProperty-'+ propertyIndex"
						v-model="additionalRealProperty.useProperty"
						size="sm"
						reset-undefined
						:options="[
							...partnerChoices,
							{ text: 'Both', value: 'both' },
							{ text: 'Neither', value: 'neither' },
						]"
						:data-testid="'additional-property-will-either-party-have-use-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>

				<b-form-group
					:id="'input-manageExpenses-'+ propertyIndex"
					label="How will the parties manage property expenses until it is sold?"
					:label-for="'manageExpenses-'+ propertyIndex"
				>
					<b-form-select
						:id="'manageExpenses-'+ propertyIndex"
						v-model="additionalRealProperty.manageExpenses"
						size="sm"
						:options="[
							...partnerResponsibleChoices,
							{
								text: 'They will share costs equally',
								value: 'equally',
							},
							{
								text: 'The will share costs as agreed',
								value: 'as-agreed',
							},
							{
								text: 'They will share costs based on an agreed-upon schedule',
								value: 'schedule',
							},
						]"
						:data-testid="'additional-property-expense-management-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>

			<yes-no-choice
				v-model="additionalRealProperty.currentlyRented"
				label="Is their currently rental income being generated by the property?"
				reset-undefined
				class="my-3"
				:testid="'additional-property-currently-rented-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="additionalRealProperty.currentlyRented"
			>
				<input-group
					v-model="additionalRealProperty.rentalAmount"
					reset-undefined
					class="my-3"
					dollar
					label="What is the rental income amount?"
					:testid="'additional-property-rental-income-amount-'+testid"
					:disabled="isViewMode"
				/>

				<b-form-group
					:id="'input-manageRentalIncome-'+ propertyIndex"
					label="How will parties manage rental income from the property until it is sold?"
					:label-for="'manageRentalIncome-'+ propertyIndex"
				>
					<b-form-select
						:id="'manageRentalIncome-'+ propertyIndex"
						v-model="additionalRealProperty.manageRentalIncome"
						size="sm"
						reset-undefined
						:options="[
							...partnerRentChoices,
							{
								text: 'They will share the rent equally',
								value: 'equally',
							},
							{
								text: 'They will share the Rent as agreed between them',
								value: 'as-agreed',
							},
						]"
						:data-testid="'additional-property-manage-rental-income-'+testid"
						class="col-lg-8"
						:disabled="isViewMode"
					/>
				</b-form-group>
			</conditional-group>
		</conditional-group>
		</template>
		<template v-if="!clientUserAgreement">
			<additional-clauses
				:newLabel="'Add Clause to Recitals'" class="mt-2"
				v-model="additionalRealProperty.additionalClausesToRecitals" :title="'Additional Clauses into Recitals'" :testid="'additional-property-add-clause-to-recitals-'+testid+'-'"
				:disabled="isViewMode"
			/>

			<additional-clauses
				:newLabel="'Add Clause to Section'" class="mt-2"
				v-model="additionalRealProperty.additionalClauses" :title="'Additional Clauses into Sections'" :testid="'additional-property-add-clause-'+testid+'-'"
				:disabled="isViewMode"	
			/>
		</template>
	</div>
</template>
