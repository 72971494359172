var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tbody',[(_vm.label)?_c('b-tr',{staticClass:"section-header"},[_c('b-th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.labelId === 'real-property' ? (_vm.agreementData && _vm.agreementData.agreement && _vm.agreementData.agreement.propertyRecitals.includes('Additional Real Property')) ? true: false : true)?_c('span',{staticClass:"print-hide"},[(_vm.isAddEnabled)?_c('b-icon-plus',{attrs:{"font-scale":"1.5","variant":"info","data-testid":'add-'+ _vm.labelId},on:{"click":function($event){return _vm.showAddModal(_vm.labelId)}}}):_vm._e()],1):_vm._e()]),_c('b-td',{attrs:{"variant":"success"}}),(!_vm.isAssetView)?[_c('b-td'),_c('b-td'),_c('b-td',{attrs:{"variant":"success"}}),_c('b-td'),_c('b-td'),_c('b-td',{staticClass:"print-hide"})]:_vm._e()],2):_vm._e(),(_vm.items)?_c('ul',{staticClass:"items-container"},_vm._l((_vm.items),function(item,index){return _c('fragment',{key:index},[_c('b-tr',{directives:[{name:"show",rawName:"v-show",value:(item.description.trim()),expression:"item.description.trim()"}]},[_c('b-td',{staticClass:"description",style:(item.descriptionStyle || '')},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('b-td',{staticClass:"align-bottom"},[_vm._v(_vm._s(item.ownership))]),_c('b-td',{staticClass:"align-bottom text-center"},[(item.type=='corporation' && item.shared && item.shared != '-' &&
				(item.spouseOne!=0 || item.spouseTwo!=0))?[_vm._v(" $"+_vm._s(_vm.formatMoney(item.shared)))]:_vm._e(),(item.notes)?_c('fragment',[_c('table',{staticClass:"table b-table table-sm b-table-caption-top",staticStyle:{"margin-bottom":"0px !important"}},[_c('b-tbody',_vm._l((item.notes),function(note,index){return _c('tr',{key:index,staticClass:"w-100"},[_c('td',{class:("text-left w-100 " + (note.class ? note.class : ''))},[_vm._v(" "+_vm._s(note.label)+" ")]),_c('td',{class:("text-right w-100 " + (note.class ? note.class : ''))},[_vm._v(" $"+_vm._s(_vm.formatMoney(note.value))+" ")])])}),0)],1)]):_c('fragment',[_vm._v(_vm._s(item.comments))])],2),_c('b-td',{staticClass:"text-right align-bottom",attrs:{"variant":"success"}},[(item.type=='corporation' && item.value != '-' &&
				(item.spouseOne==0 && item.spouseTwo==0))?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.value))+" ")]):(item.type!='corporation' && item.value != '-')?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.value))+" ")]):(item.details)?_c('span'):(item.value)?_c('span',[_vm._v(_vm._s(item.value))]):_vm._e()]),(!_vm.isAssetView)?[_c('b-td',{staticClass:"text-right align-bottom"},[(item.spouceOneExclusions)?_c('span',[(
							!item.details ||
							(item.details && !item.details.length)
						)?_c('span',[_vm._v("$"+_vm._s(_vm.formatMoney(item.spouceOneExclusions)))]):_vm._e()]):_vm._e()]),_c('b-td',{staticClass:"text-right align-bottom"},[(item.spouceTwoExclusions)?_c('span',[(
							!item.details ||
							(item.details && !item.details.length)
						)?_c('span',[_vm._v("$"+_vm._s(_vm.formatMoney(item.spouceTwoExclusions)))]):_vm._e()]):_vm._e()]),_c('b-td',{staticClass:"text-right align-bottom",attrs:{"variant":"success"}},[(item.type=='corporation' && item.shared != '-' &&
				(item.spouseOne==0 && item.spouseTwo==0))?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.shared))+" ")]):_vm._e(),(item.type!='corporation' && item.shared != '-')?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.shared))+" ")]):(item.type!='corporation' && item.shared)?_c('span',[_vm._v(_vm._s(item.shared))]):_vm._e()]),_c('b-td',{staticClass:"text-right align-bottom"},[(
						(item.spouseOne ||
							item.keeper == '0' ||
							item.keeper == 'joint') &&
						(!item.details ||
							(item.details && !item.details.length))
					)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouseOne))+" ")]):_vm._e()]),_c('b-td',{staticClass:"text-right align-bottom"},[(
						(item.spouseTwo ||
							item.keeper == '1' ||
							item.keeper == 'joint') &&
						(!item.details ||
							(item.details && !item.details.length))
					)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouseTwo))+" ")]):_vm._e()])]:_vm._e(),_c('b-td',{staticClass:"print-hide bottom-align"},[(_vm.getCurrentRoute !='preview' && _vm.isEditEnabled)?_c('b-icon-pencil-fill',{attrs:{"font-scale":"1.2","variant":"info","data-testid":(_vm.labelId+'-'+index)},on:{"click":function($event){return _vm.showEditModal('edit-modal',_vm.labelId,_vm.items,item,index)}}}):_vm._e()],1)],2),_vm._l((item.details),function(item,index){return _c('b-tr',{key:index},[_c('b-td',{staticClass:"description",style:(item.descriptionStyle || '')},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('b-td',[_vm._v(_vm._s(item.ownership))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(item.comments))]),_c('b-td',{staticClass:"text-right",attrs:{"variant":"success"}},[(item.value != '-')?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.value))+" ")]):(item.value)?_c('span',[_vm._v(_vm._s(item.value))]):_vm._e()]),(!_vm.isAssetView)?[_c('b-td',{staticClass:"text-right"},[(item.spouceOneExclusions)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouceOneExclusions))+" ")]):_vm._e()]),_c('b-td',{staticClass:"text-right"},[(item.spouceTwoExclusions)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouceTwoExclusions))+" ")]):_vm._e()]),_c('b-td',{staticClass:"text-right",attrs:{"variant":"success"}},[_vm._v(" $"+_vm._s(_vm.formatMoney(item.shared))+" ")]),_c('b-td',{staticClass:"text-right"},[(
						item.spouseOne ||
						item.keeper == '0' ||
						item.keeper == 'joint'
					)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouseOne))+" ")]):_vm._e()]),_c('b-td',{staticClass:"text-right"},[(
						item.spouseTwo ||
						item.keeper == '1' ||
						item.keeper == 'joint'
					)?_c('span',[_vm._v(" $"+_vm._s(_vm.formatMoney(item.spouseTwo))+" ")]):_vm._e()])]:_vm._e()],2)})],2)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }