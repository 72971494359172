var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"section-heading",attrs:{"slot":"header"},slot:"header"},[_vm._v("Pensions")]),_c('list-item',{attrs:{"object-prototype":_vm.newAccount,"testid":'pension-',"hideAddBtn":_vm.hideForEdit,"isEditView":_vm.hideForEdit,"itemIndexToShow":_vm.itemIndexToShow,"forceAddNewItem":_vm.forceAddNewItem,"disabled":_vm.isViewMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var account = ref.item;
var index = ref.index;
return [(!_vm.forceAddNewItem || (_vm.forceAddNewItem && index == (_vm.accounts.length-1)))?[_c('input-group',{staticClass:"mb-2",attrs:{"input-cols":"8","label":"Name of pension","reset-undefined":"","placeholder":"ie: Public Service Pension","testid":'pension-name-'+index,"disabled":_vm.isViewMode},model:{value:(account.name),callback:function ($$v) {_vm.$set(account, "name", $$v)},expression:"account.name"}}),_c('radio-group',{staticClass:"my-3",attrs:{"agreement-data":_vm.agreementData,"input-cols":"4","label":"How is the pension owned?","reset-undefined":"","partner":"","select":"","testid":'pension-ownership-'+index,"length":'long',"disabled":_vm.isViewMode},model:{value:(account.ownership),callback:function ($$v) {_vm.$set(account, "ownership", $$v)},expression:"account.ownership"}}),_c('radio-group',{staticClass:"my-2",attrs:{"input-cols":"4","label":"What type of pension plan?","reset-undefined":"","select":"","options":[
				{ text: 'Extraprovincial', value: 'extraprovincial' },
				{ text: 'Local', value: 'local' } ],"testid":'pension-plan-type-'+index,"disabled":_vm.isViewMode},model:{value:(account.planType),callback:function ($$v) {_vm.$set(account, "planType", $$v)},expression:"account.planType"}}),(!_vm.clientUserAgreement)?[_c('yes-no-choice',{staticClass:"my-3",attrs:{"reset-undefined":"","label":"Do the parties wish to assign a value to the pension?","testid":'pension-parties-assign-value-'+index,"disabled":_vm.isViewMode},model:{value:(account.partiesAssignValue),callback:function ($$v) {_vm.$set(account, "partiesAssignValue", $$v)},expression:"account.partiesAssignValue"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":account.partiesAssignValue}},[_c('yes-no-choice',{staticClass:"my-3",attrs:{"reset-undefined":"","label":"Did the parties obtain a valuation of the pension?","testid":'pension-obtained-valuation-'+index,"disabled":_vm.isViewMode},model:{value:(account.obtainedValuation),callback:function ($$v) {_vm.$set(account, "obtainedValuation", $$v)},expression:"account.obtainedValuation"}}),_c('input-group',{staticClass:"my-3",attrs:{"reset-undefined":"","dollar":"","label":"What is the value of the pension?","testid":'pension-value-'+index,"disabled":_vm.isViewMode},model:{value:(account.value),callback:function ($$v) {_vm.$set(account, "value", $$v)},expression:"account.value"}}),_c('radio-group',{staticClass:"my-2",attrs:{"input-cols":"4","label":"Is this a before-tax or after-tax value?","reset-undefined":"","select":"","options":[
					{ text: 'before-tax value', value: 'before-tax' },
					{ text: 'after-tax value', value: 'after-tax' } ],"testid":'order-of-tax-'+index,"disabled":_vm.isViewMode},model:{value:(account.orderOfTax),callback:function ($$v) {_vm.$set(account, "orderOfTax", $$v)},expression:"account.orderOfTax"}})],1),_c('radio-group',{staticClass:"my-3",attrs:{"input-cols":"10","label":"What will the owner do with the pension upon separation?","reset-undefined":"","select":"","options":[
				{
					text: 'Owner will keep, and the other party will waive their interest',
					value: 'keep',
				},
				{
					text: 'Divide, in accordance with the Family Law Act',
					value: 'divide',
				} ],"testid":'pension-upon-separation-'+index,"length":'long',"disabled":_vm.isViewMode},model:{value:(account.plan),callback:function ($$v) {_vm.$set(account, "plan", $$v)},expression:"account.plan"}}),_c('additional-clauses',{staticClass:"mt-2",attrs:{"title":'Additional Clauses into Recitals',"newLabel":'Add Clause to Recitals',"testid":'pension-add-clause-to-recitals-'+index+'-',"disabled":_vm.isViewMode},model:{value:(account.additionalClausesToRecitals),callback:function ($$v) {_vm.$set(account, "additionalClausesToRecitals", $$v)},expression:"account.additionalClausesToRecitals"}}),_c('additional-clauses',{staticClass:"mt-2",attrs:{"title":'Additional Clauses into Sections',"newLabel":'Add Clause to Section',"testid":'pension-add-clause-'+index+'-',"disabled":_vm.isViewMode},model:{value:(account.additionalClauses),callback:function ($$v) {_vm.$set(account, "additionalClauses", $$v)},expression:"account.additionalClauses"}})]:_vm._e()]:_vm._e()]}}]),model:{value:(_vm.accounts),callback:function ($$v) {_vm.accounts=$$v},expression:"accounts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }